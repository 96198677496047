import React, { useContext, useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import Web3Context from "../../core/web3/Web3Context";
import ConnectWalletButton from '../Account/ConnectWalletButton';

const showBuyNow = true;

const Navbar: React.FC = () => {
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const web3Props = useContext(Web3Context);

  useEffect(() => {
    if (web3Props.provider !== null && web3Props.setAddress && web3Props.setProvider) {
      setIsWalletConnected(true);
    } else {
      setIsWalletConnected(false);
    }
  }, [web3Props.address, web3Props.provider, web3Props.setAddress, web3Props.setProvider]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  
  return (
    <>
      <style jsx global>{`
        a, a:active, a:focus, a:hover {
          outline: none;
          text-decoration: none;
          color: white;
          transition: transform 0.3s ease;
        }
        a:hover {
          transform: scale(1.1);
        }
        .wallet-connect-container {
          display: flex;
          border-radius: 9999px; 
          align-items: center;
          justify-content: center;
          padding: 0 0.6vw;
          gap: 0.5vw;
        }
        .wallet-connect-container-dropdown {
          display: none;
          border-radius: 9999px; 
          align-items: center;
          justify-content: center;
          padding: 0 0.6vw;
          gap: 0.5vw;
        }

        .red-background {
          background-color: darkred;
          border: 0.2vw solid red;
        }
        .blue-background {
          background-color: #2E3192;
          border: 0.2vw solid #0071BC;
        }
        .nav-holder {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          min-height: 60px;
          padding: 0 24px;
          background: linear-gradient(135deg, #5b0000, #000000);
          color: #fff;
          position: relative;
          z-index: 1000;
        }
        .logo-nav-container {
          display: flex;
          align-items: center;
        }
        .logo-text {
          font-weight: bold;
          font-size: 30px;
          color: #fff;
          margin: 0;
        }
        .nav-links {
          display: flex;
          height: 100%;
          align-items: center;
          flex-direction: row;
        }
        .nav-dropdown-container {
          display: flex;
          align-items: center;
          position: relative;
          width: auto;
          padding: 0 15px;
          font-size: 21px;
          cursor: pointer;
          font-weight: 500;
          white-space: nowrap;
          transition: all 0.3s ease 0s;
          text-decoration: none !important;
          color: white;
        }
        .hook-nav-user {
          display: flex;
          align-items: center;
        }
        .userOptions {
          display: flex;
          align-items: center;
          flex-direction: row;
        }
        .insideOptions {
          display: flex;
          align-items: center;
          margin-right: 0px;
          flex-direction: row;
          width: auto;
          padding: 0px;
        }
        .navUserItem {
          cursor: pointer;
          padding: 0px;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          white-space: nowrap;
          text-decoration: none !important;
          background-clip: border-box !important;
        }
        .buy-phoenix-button {
          font-size: 1.2vw;
          font-weight: bold;
          padding: 0.5vw 1vw;
          border-radius: 9999px; 
          background-color: rgba(50, 205, 50, 0.8);
          color: white;
          transition: background-color 0.3s ease, transform 0.3s ease;
        }
        .buy-phoenix-button:hover {
          background-color: rgba(50, 205, 50, 1);
          transform: scale(1.1);
        }
        .nav-separator {
          border: none;
          flex-shrink: 0;
          background: #D3D3D3;
          width: 1px;
          margin: 0 20px;
          height: 16px;
        }
        .hamburger {
          display: none;
          cursor: pointer;
          font-size: 4vw;
        }
        .modal {
          display: ${isModalOpen ? 'flex' : 'none'};
          flex-direction: column;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.9);
          justify-content: center;
          align-items: center;
          z-index: 2000;
        }
        .modal a {
          font-size: 4vw;
          margin: 20px 0;
        }
        .modal .close-btn {
          position: absolute;
          top: 20px;
          right: 20px;
          background: red;
          color: white;
          font-size: 4vw;
          width: 10vw;
          height: 10vw;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;
          transition: background 0.3s ease;
        }
        .modal .close-btn:hover {
          background: lightcoral;
        }

        .separator-small {
          background: #D3D3D3;
          width: 1px;
          height: 24px;
          margin: 0 1vw;
        }
        @media (max-width: 1400px) {
          .nav-dropdown-container {
            font-size: 17px;
          }
          .logo-text {
            font-size: 24px;
          }
        }

        @media (max-width: 1200px) {
          .logo-text {
            font-size: 20px;
          }
          .nav-dropdown-container {
            font-size: 13px;
          }
        }
        @media (min-width: 1200px) {
          .nav-separator {
            display: block; /* Show separator */
          }
        }

        @media (max-width: 1000px) {
          .logo-text {
            font-size: 3vw;
            white-space: nowrap;
          }
          .nav-links {
            display: none;
          }
          .hamburger {
            display: block;
          }
          .modal a {
            font-size: 4vw;
          }
          .insideOptions {
            flex-direction: row;
            align-items: center;
          }
          .buy-phoenix-button {
            font-size: 2.5vw;
            margin-bottom: 0;
          }
          .wallet-connect-container {
            display: none;
          }
          .wallet-connect-container-dropdown {
            display: block;
          }
          .separator-small, .nav-separator {
            display: none;
          }
          .hamburger-container {
            display: flex;
            align-items: center;
            gap: 1vw;
          }
        }
        @media (max-width: 800px) {
          .logo-text {
            font-size: 3.5vw;
            white-space: nowrap;
          }
          .nav-links {
            display: none;
          }
          .hamburger {
            display: block;
          }
          .modal a {
            font-size: 4.5vw;
          }
          .insideOptions {
            flex-direction: row;
            align-items: center;
          }
          .buy-phoenix-button {
            font-size: 3vw;
          }
          .wallet-connect-container {
            display: none;
          }
          
          .separator-small, .nav-separator {
            display: none;
          }
          .hamburger-container {
            display: flex;
            align-items: center;
            gap: 1vw;
          }
        }
        @media (max-width: 600px) {
          .nav-holder {
            flex: 1;
            padding: 0 20px;
          }
          .logo-text {
            font-size: 4.5vw;
            white-space: nowrap;
          }
          .modal a {
            font-size: 6vw;
          }
          .nav-separator {
            margin: 0 1.5vw;
          }
          .nav-dropdown-container {
            padding: 0;
          }
          .buy-phoenix-button {
            font-size: 3.5vw;
            padding: 1vw 2vw;
          }
          .hamburger {
            font-size: 5vw;
          }
        }
        @media (max-width: 450px) {
          .logo-text {
            font-size: 6.5vw;
            white-space: nowrap;
          }
        }
        @media (max-width: 450px) {
          .logo-text {
            font-size: 7.5vw;
            white-space: nowrap;
          }
        }










        .navbar-separator {
  border: none;
  flex-shrink: 0;
  background: #D3D3D3;
  display: block;
  width: 1px;
  margin: 0px 20px 0px 18px;
  height: 16px !important;
}

.navbar-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 80px;
  transition: background-color 0.3s ease;
  background-color: rgb(80, 0, 40);
  border-radius: 10px;
  margin: 6px 10px;
}
  
.navbar-item:hover {
  background-color: rgb(150, 20, 90);
}

.navbar-user {
  display: flex;
  align-items: center;
}

.user-options {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.user-inside-options {
  display: flex;
  align-items: center;
  margin-right: 0px;
  flex-direction: row;
  width: auto;
  padding: 0px;
  justify-content: unset;
}

.user-item {
  color: rgb(29, 29, 29);
  cursor: pointer;
  padding: 0px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  white-space: nowrap;
  text-decoration: none !important;
  background-clip: border-box !important;
}

.sign-in-button {
  width: unset;
  padding: 9px 12px;
  font-weight: 500;
  font-size: 19px;
  line-height: 130%;
  text-align: center;
  color: white;
  margin: 0px;
  transition: all 0.3s ease 0s;
  border: unset;
  border-radius: 24px;
  cursor: pointer;
}

.pinx-text {
  color: #ff69b4;
  text-shadow: 0 0 8px rgba(255, 105, 180, 0.7);
}

.price-text {
  color: #00ff00;
  text-shadow: 0 0 8px rgba(0, 255, 0, 0.7);
}

.language-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 20px;
  margin-left: 12px;
  background-clip: padding-box !important;
}

.global-icon {
  width: 25px;
  height: auto;
  fill: black;
  vertical-align: middle;
}


@media (max-width: 1000px) {
  .navbar-user {
    display: none;
  }
}
      `}</style>
    
      <nav className="nav-holder">
        <div className="logo-nav-container">
          <div className="logo-text">
            <Link href="/" passHref>
              <a className="logo-text">PinXchange</a>
            </Link>
          </div>
          <div className="nav-separator"></div> {/* Separator between Phoenix Inu and Whitepaper */}
          <div className="nav-links">
            <div className="nav-dropdown-container">
              <Link href="/tokenomics" passHref>
                <a>Tokenomics</a>
              </Link>
            </div>
            <div className="nav-dropdown-container">
              <Link href="/roadmap" passHref>
                <a>Roadmap</a>
              </Link>
            </div>
            <div className="nav-dropdown-container">
            <Link href="/#steps" passHref>
                <a>How to Buy</a>
              </Link>
            </div>
            <div className="nav-dropdown-container">
              <Link href="/support" passHref>
                <a>Support</a>
              </Link>
            </div>
            <div className="nav-dropdown-container">
              <Link href="/about" passHref>
                <a>About</a>
              </Link>
            </div>
          </div>
        </div>
        <div className="navbar-user">
          <div className="user-options">
            <div className="user-inside-options">
              <Link href="https://dexscreener.com/solana/6m32vkef22fzvv2nc69wnabdax7wdn4zsggwjujurqev" passHref>
                <a style={{ textDecoration: 'none' }}>
                  <div className="sign-in-button">
                    <span className="pinx-text">$PINX</span> <span className="price-text">$0.000219</span>
                  </div>
                </a>
              </Link>
              <div className="navbar-separator"></div>
                <ConnectWalletButton size="100" showUserDetails={false} />
              <div className="navbar-separator"></div>
            </div>
          </div>
          <div className="user-item">
            <div className="language-selector">
              <Image 
                src="/assets/globe.png"
                alt="Global Icon"
                className="global-icon"
                width={50}
                height={50}
                layout="fixed"
              />
            </div>
          </div>
        </div>
        <div className="hamburger" onClick={toggleModal}>
                  &#9776;
                </div>
      </nav>
      <div className="modal">
        <div className="close-btn" onClick={toggleModal}>X</div>
        <Link href="/" passHref>
          <a onClick={toggleModal}>Home</a>
        </Link>
        <Link href="/tokenomics" passHref>
          <a onClick={toggleModal}>Tokenomics</a>
        </Link>
        <Link href="/roadmap" passHref>
          <a onClick={toggleModal}>Roadmap</a>
        </Link>
        <Link href="/#steps" passHref>
          <a onClick={toggleModal}>How to Buy</a>
        </Link>
        <Link href="/about" passHref>
          <a onClick={toggleModal}>About</a>
        </Link>
        <ConnectWalletButton size="100" showUserDetails={false} />
      </div>
    </>
  );
};

export default Navbar;



