import Web3Modal from "web3modal";
import { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import Web3Context, { Web3ContextProps } from "../../core/web3/Web3Context";
import React from "react";
import { providerOptions } from "../../core/web3/web3";

export async function disconnectWallet(web3Props: Web3ContextProps) {
  if (typeof window !== 'undefined' && web3Props.setAddress && web3Props.setProvider) {
    web3Props.setAddress("");
    web3Props.setProvider(null);

    // Clear Web3Modal cache
    const web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions,
    });
    web3Modal.clearCachedProvider();
  }
}

export async function connectWallet(web3Props: Web3ContextProps) {
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions,
    });

    const provider = await web3Modal.connect();
    if (web3Props.setProvider && web3Props.setAddress && provider) {
      web3Props.setProvider(provider);

      // Get a Web3 instance for the wallet
      const web3 = new Web3(provider);

      // Get list of accounts of the connected wallet
      const accounts = await web3.eth.getAccounts();

      // MetaMask does not give you all accounts, only the selected account
      const selectedAccount = accounts[0];
      web3Props.setAddress(selectedAccount);
    }
  } catch (error) {
    console.error(error);
  }
}

const ConnectWalletButton = ({ size, showUserDetails }: { size: string; showUserDetails: boolean }) => {
  const [connected, setConnected] = useState(false);
  const web3Props = useContext(Web3Context);

  useEffect(() => {
    if (web3Props.address) {
      setConnected(true);
    }
  }, [web3Props.address]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const web3Modal = new Web3Modal({
        cacheProvider: true, // enable caching to remember the provider
        providerOptions, // your provider options
      });

      if (web3Modal.cachedProvider) {
        connectWallet(web3Props); // attempt to reconnect using the cached provider
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (connected) {
    if (!web3Props.address || web3Props.address === "") {
      setConnected(false);
    }
    return (
      <>
        <style>
          {`
            .CONNECT-custom-connect-button-style {
              font-size: 1.2vw;
              font-weight: bold;
              padding: 0.25vw 0.5vw;
              border-radius: 10px;
              background-color: dodgerblue;
              color: white;
              transition: background-color 0.3s;
            }

            .CONNECT-disconnect-button {
              font-size: 1.2vw;
              font-weight: bold;
              padding: 0.25vw 0.5vw;
              border-radius: 10px;
              background-color: crimson;
              color: white;
              transition: background-color 0.3s;
            }
            .CONNECT-disconnect-button:hover {
              background-color: red;
            }
            @media screen and (min-width: 600px) and (max-width: 900px) {
              .CONNECT-custom-connect-button-style {
                font-size: 4.5vw;
              }
              .CONNECT-disconnect-button {
                font-size: 4.5vw;
              }
              .CONNECT-disconnect-button:hover {
                padding-right: 0.6vw;
              }
            }

            @media screen and (max-width: 600px) {
              .CONNECT-custom-connect-button-style {
                font-size: 4.5vw;
              }
              .CONNECT-disconnect-button {
                font-size: 4.5vw;
              }
              .CONNECT-disconnect-button:hover {
                padding-right: 0.6vw;
              }
            }
          `}
        </style>

        <div className="flex justify-center">
          <button
            className="CONNECT-disconnect-button"
            onClick={() => {
              setConnected(false);
              disconnectWallet(web3Props);
            }}
          >
            Disconnect wallet
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <style>
        {`
          .CONNECT-custom-connect-button-style {
            font-size: 1.2vw;
            font-weight: bold;
            padding: 0.25vw 0.5vw;
            border-radius: 10px;
            background-color: dodgerblue;
            color: white;
            transition: background-color 0.3s;
          }

          .CONNECT-custom-connect-button-style:hover {
            background-color: blue;
          }
          .CONNECT-disconnect-button {
            font-size: 1.2vw;
            font-weight: bold;
            padding: 0.25vw 0.5vw;
            border-radius: 10px;
            background-color: crimson;
          }
          @media screen and (max-width: 900px) {
            .CONNECT-custom-connect-button-style {
              font-size: 4.5vw;
            }
            .CONNECT-custom-connect-button-style:hover {
              background-color: blue;
              padding-right: 0.6vw;
            }

            .CONNECT-disconnect-button {
              font-size: 4.5vw;
            }
          }
        `}
      </style>

      <div className="flex justify-center">
        <button
          className="CONNECT-custom-connect-button-style"
          onClick={async () => {
            await connectWallet(web3Props);
          }}
        >
          Connect wallet
        </button>
      </div>
    </>
  );
};

export default ConnectWalletButton;

